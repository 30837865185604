export default class EventsTabs {
    constructor() {
      const tabLinks = document.querySelectorAll('.event-tabs .tab-titles a');
      const tabPanes = document.querySelectorAll('.event-tabs .tab-content .tab-pane'); 
      const tabTitles = document.querySelectorAll('.tab-titles li');

      tabTitles.forEach(tabTitle => {
        tabTitle.addEventListener('click', () => {
          tabTitles.forEach(tab => {
            tab.classList.remove('active');
          });
      
          tabTitle.classList.add('active');
        });
        });  
  
      tabLinks.forEach(function(link) {
        link.addEventListener('click', function(e) {
          e.preventDefault();
  
          tabLinks.forEach((link) => { 
            link.classList.remove('active');
          });
  
          link.classList.add('active');
  
          const target = link.getAttribute('href')?.substring(1) || '';
  
          tabPanes.forEach(function(pane) {
            pane.style.display = 'none';
          });
  
          const targetElement = document.getElementById(target);
          if (targetElement) {
            targetElement.style.display = 'flex';
          }
        });
      });
  
      // Hide all tabs except 'all-events' when the page is loaded
      tabPanes.forEach(function(pane) {
        if (pane.id !== 'all-events') {
          pane.style.display = 'none';
        }
      });
    };
  }