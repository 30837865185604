import Swiper from 'swiper';

import 'swiper/css/bundle';

export default class TestimonialWidget {
    constructor() {
        const widgets = document.querySelectorAll('.testimonials');

        if (!widgets.length) {
            return;
        }

        widgets.forEach((widget) => {
            const swiper = new Swiper(widget as HTMLElement, {
                direction: 'vertical',
                loop: false,
                // autoHeight: true,
                // height: 300,
                slidesPerView: 3,
                spaceBetween: -80,
                enabled: false,
            });
        });
    }
}
