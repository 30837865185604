import '@styles/tailwind.css';
import '@styles/main.scss';
import TestimonialWidget from './widgets/testimonials-widget';
import 'photoswipe/dist/photoswipe.css';
import Gallery from "@scripts/components/gallery";
import menu from "@scripts/components/menu";
import EventsTabs from './components/events-tabs';

document.addEventListener('DOMContentLoaded', () => {
    new TestimonialWidget();
    new Gallery();
    menu();
    new EventsTabs();
});
